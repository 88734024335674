import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

// Router'ı uygulamaya ekliyoruz
app.use(router)

// Uygulamayı başlatıyoruz
app.mount('#app')