<template>
    <div class="flex-grow flex flex-col w-full">
      <!-- Header -->
      <header class="p-4 flex justify-between items-center border-b border-gray-200">
        <button @click="$emit('toggle-sidebar')" class="md:hidden">
          <menu-icon size="24" />
        </button>
        <h1 class="text-xl font-semibold">Masina.Chat</h1>
        <button 
          @click="startNewChat"
          class="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full text-sm hover:bg-yellow-200 transition-colors flex items-center"
        >
          <plus-icon size="16" class="mr-1" /> Conversație Nouă
        </button>
      </header>
  
      <!-- Chat Area -->
      <main class="flex-grow overflow-y-auto p-4 flex flex-col">
        <!-- Inline Tutorial -->
        <div v-if="showTutorial" class="intro-tutorial bg-blue-50 border-b border-blue-200 p-4 shadow-sm">
        <div class="max-w-3xl mx-auto relative">
          <h2 class="text-lg font-semibold text-blue-800 mb-2">Bine ați venit la Masina.Chat!</h2>
          <ul class="list-disc list-inside space-y-1 text-blue-700">
            <li>Acum, cu Masina.chat, puteți găsi mașina dorită foarte rapid.</li>
            <li>Specificați marca, modelul, intervalul de ani, bugetul, opțiunile de transmisie (automată, manuală) și tipul de combustibil (benzină, diesel, hibrid) pentru mașina pe care doriți să o cumpărați.</li>
            <li>De exemplu: "Vreau să cumpăr un Mercedes C180, 2014-2017, 15-25 mii de euro, automat."</li>
            <li>Dacă nu găsiți rezultatul dorit, oferiți mai multe detalii și informații.</li>
          </ul>
          <button @click="closeTutorial" class="absolute top-0 right-0 text-blue-500 hover:text-blue-700">
            <x-icon size="20" />
          </button>
        </div>
        </div>

        <div v-for="msg in messages" :key="msg.id" :class="['mb-4', { 'text-right': msg.sender === 'user', 'text-left': msg.sender !== 'user' }]">
          <span :class="['inline-block p-3 rounded-lg', { 'bg-blue-500 text-white': msg.sender === 'user', 'bg-gray-200': msg.sender !== 'user' }]">
            {{ msg.text }}
          </span>
        </div>
        
        <div v-if="isLoading" class="flex justify-center items-center my-4">
          <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
        
        <div v-if="showCarSuggestions && carSuggestions.length > 0" class="mt-4">
          <h3 class="font-bold mb-2">Iată câteva recomandări de mașini potrivite pentru tine: </h3>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <router-link 
            v-for="(car, index) in carSuggestions" 
            :key="index" 
            :to="{ name: 'AnuntPage', params: { id: car.id }}"
            class="border border-gray-200 rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300 block"
          >
            <img :src="car.image" :alt="car.name" class="w-full h-40 object-cover" />
            <div class="p-4">
              <h4 class="font-bold text-lg mb-1">{{ car.name }}</h4>
              <p class="text-sm">{{ car.year }} • {{ car.km }} • {{ car.fuel }}</p>
              <p class="text-sm">{{ car.power }} • {{ car.engine }}  • {{ car.gearbox }}</p>
              <p class="text-red-600 font-bold mt-2">{{ car.price }}</p>
            </div>
          </router-link>
          </div>
          
          <div class="mt-4 flex flex-wrap gap-2">
            <button
              v-for="filter in filterOptions"
              :key="filter"
              @click="applyFilter(filter)"
              class="px-3 py-1 rounded-full text-sm transition-colors duration-200 bg-gray-200 hover:bg-gray-300 text-gray-800"
            >
              {{ filter }}
            </button>
          </div>
        </div>
        
        <div v-if="messages.length === 0" class="flex flex-col items-center justify-center h-full">
          <div class="w-20 mb-6"   >
            <!-- SVG icon can be added here -->
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-2xl w-full">
            <button 
              v-for="(suggestion, index) in suggestions"
              :key="index" 
              class="p-4 bg-gray-50 rounded-lg text-left hover:bg-gray-100 transition-colors duration-200"
              @click="sendMessage(suggestion.text)"
            >
              <p class="text-sm">{{ suggestion.text }}</p>
            </button>
          </div>
        </div>
      </main>
  
      <!-- Input Area -->
      <div class="p-4 border-t border-gray-200">
        <div class="max-w-3xl mx-auto flex items-center">
          <input
            v-model="inputText"
            @keypress.enter="sendMessage()"
            type="text"
            placeholder="Introduceți caracteristicile mașinii sau bugetul"
            class="flex-grow p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div class="relative inline-block">
            <button
              @click="toggleRecording"
              class="ml-2 p-3 text-gray-500 hover:text-gray-700 relative"
            >
              <MicIcon :size="20" :color="isRecording ? 'red' : 'currentColor'" />
            </button>
            <div v-if="showTooltip" class="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-blue-500 text-white text-xs py-1 px-2 rounded shadow-lg whitespace-nowrap">
                Nou! Încercați mesajul vocal.
              <div class="arrow-down"></div>
            </div>
          </div>
          <button @click="sendMessage()" class="ml-2 p-3 text-gray-500 hover:text-gray-700">
            <send-icon size="20" />
          </button>
        </div>
        <p class="text-center text-xs text-gray-500 mt-2">
          Masina.Chat can make mistakes. Datele sunt de pe Autovit.ro
        </p>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { Plus, Send, Menu, Mic, X } from 'lucide-vue-next';
  import axios from 'axios';
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'HomePage',
    components: {
      PlusIcon: Plus,
      SendIcon: Send,
      MenuIcon: Menu,
      MicIcon: Mic,
      XIcon: X
    },
    props: ['isSidebarOpen'],
    setup() {
      const router = useRouter();
      const inputText = ref('');
      const messages = ref([]);
      const showCarSuggestions = ref(false);
      const isLoading = ref(false);
      const carSuggestions = ref([]);
      const conversationId = ref(null);
      const filterOptions = ref([]);
      const showTutorial = ref(true);

      const isRecording = ref(false);
      let mediaRecorder = null;
      let audioChunks = [];
      const showTooltip = ref(true);

      const closeTutorial = () => {
        showTutorial.value = false;
        localStorage.setItem('tutorialSeen', 'true');
      };

      const fetchFilterOptions = async () => {
        try {
          const response = await axios.get('https://masinaapi.pera.network/api/filter-recommendations');
          filterOptions.value = response.data.map(option => option.filter_name);
        } catch (error) {
          console.error('Filter options fetching error:', error);
        }
      };
  
      const suggestions = ref([
        { text: "🇩🇪 Mercedes C180, 2012-2017, Maximum 25000 eur" },
        { text: "⭐ BMW X3, 20000-30000 EUR" },
        { text: "⭐ Audi A4, Nou, 18000-28000 EUR" },
        { text: "🔍 Volkswagen Golf, 12000-22000 EUR" }
        // { text: "⚡ Tesla" },
      ]);

      const toggleRecording = async () => {
        showTooltip.value = false;
        if (!isRecording.value) {
            try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorder = new MediaRecorder(stream);
            
            mediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };

            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunks, { type: 'audio/ogg; codecs=opus' });
                sendAudioMessage(audioBlob);
                audioChunks = [];
            };

            mediaRecorder.start();
            isRecording.value = true;

            messages.value.push({ id: Date.now(), text: 'Am început să vă ascult, vă rog să îmi descrieți mașina pe care o doriți. Ce marcă, model, interval de preț, kilometraj, tip de transmisie. Dacă îmi explicați oricare dintre acestea, vă voi ajuta.', sender: 'bot' });
            } catch (error) {
                messages.value.push({ id: Date.now(), text: 'Nu ați acordat permisiunea de acces la microfon.', sender: 'bot' });
                console.error('Mikrofon erişimi hatası:', error);
            }
        } else {
            mediaRecorder.stop();
            isRecording.value = false;
        }
       };

       const sendAudioMessage = async (audioBlob) => {
        if (!conversationId.value) {
            await createConversation();
        }

        const formData = new FormData();
        formData.append('audio', audioBlob, 'voice_message.ogg');

        try {
            isLoading.value = true;
            const response = await axios.post(
            `https://masinaapi.pera.network/api/conversations/${conversationId.value}/audio`,
            formData,
            {
                headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
                }
            }
            );

            messages.value.push({ id: Date.now(), text: 'Mesajul vocal a fost trimis.', sender: 'user' });
            messages.value.push({ id: Date.now(), text: response.data.message, sender: 'bot' });

            // Araba önerilerini işleme
            const { cars } = response.data;
            if (cars && cars.length > 0) {
            carSuggestions.value = cars.filter(car => car.photo !== null).map(car => ({
                id: car.id,
                name: car.title,
                year: car.year,
                km: car.km,
                fuel: car.fuel,
                power: car.capacity,
                engine: car.engine,
                price: car.price,
                image: car.photo,
                gearbox: car.gearbox
            }));
            showCarSuggestions.value = true;
            }
        } catch (error) {
            console.error('Sesli mesaj gönderme hatası:', error);
            messages.value.push({ id: Date.now(), text: 'Îmi pare rău, a apărut o eroare.', sender: 'bot' });
        } finally {
            isLoading.value = false;
        }
        };
  
      const createConversation = async () => {
        try {
          const response = await axios.post('https://masinaapi.pera.network/api/conversations', {}, {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          conversationId.value = response.data.id;
          localStorage.setItem('conversationId', response.data.id);
        } catch (error) {
          console.error('Conversation oluşturma hatası:', error);
        }
      };
  
      const sendMessage = async (text = inputText.value) => {
        if (!conversationId.value) {
          await createConversation();
        }
  
        const messageText = typeof text === 'string' ? text : inputText.value;
        if (messageText.trim() !== '') {
          carSuggestions.value = [];
          showCarSuggestions.value = false;
  
          messages.value.push({ id: Date.now(), text: messageText, sender: 'user' });
          inputText.value = '';
          isLoading.value = true;
  
          fetchFilterOptions();
          
          try {
            const response = await axios.post(`https://masinaapi.pera.network/api/conversations/${conversationId.value}`, 
              { content: messageText },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                }
              }
            );
  
            const { cars } = response.data;
  
            messages.value.push({ id: Date.now(), text: response.data.message, sender: 'bot' });
  
            if (cars && cars.length > 0) {
              carSuggestions.value = cars.filter(car => car.photo !== null).map(car => ({
                id: car.id,
                name: car.title,
                year: car.year,
                km: car.km,
                fuel: car.fuel,
                power: car.capacity,
                engine: car.engine,
                price: car.price,
                image: car.photo,
                gearbox: car.gearbox
              }));
              showCarSuggestions.value = true;
              // localStorage
              localStorage.setItem('cars', JSON.stringify(carSuggestions.value));
            }
          } catch (error) {
            console.error('API hatası:', error);
            messages.value.push({ id: Date.now(), text: 'Îmi pare rău, a apărut o eroare.', sender: 'bot' });
          } finally {
            isLoading.value = false;
          }
        }
      };
  
      const applyFilter = (filter) => {
        sendMessage(filter);
      };
  
      const startNewChat = () => {
        messages.value = [];
        showCarSuggestions.value = false;
        carSuggestions.value = [];
        localStorage.removeItem('conversationId');  // Local storage'dan silin
        localStorage.removeItem('cars');  // Local storage'dan silin
        createConversation();
        router.push('/');  // Ana sayfaya yönlendirin
      };
  
      onMounted(() => {
        if (localStorage.getItem('tutorialSeen') === 'true') {
            showTutorial.value = false;
        }
        const storedConversationId = localStorage.getItem('conversationId');
        if (storedConversationId && localStorage.getItem('cars')) {
            showCarSuggestions.value = true;
            carSuggestions.value = JSON.parse(localStorage.getItem('cars'));
            conversationId.value = storedConversationId;
            messages.value.push({ id: Date.now(), text: 'V-ați întors, continuați să inspectați mașinile.', sender: 'bot' });
        } else {
            createConversation();
        }
        fetchFilterOptions();
      });
  
      return {
        inputText,
        messages,
        showCarSuggestions,
        isLoading,
        carSuggestions,
        suggestions,
        filterOptions,
        sendMessage,
        applyFilter,
        startNewChat,
        isRecording,
        toggleRecording,
        showTooltip,
        closeTutorial,
        conversationId,
        showTutorial
      };
    }
  };
  </script>

<style scoped>
.arrow-down {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #3b82f6;
}

</style>