<template>
  <div class="h-screen flex bg-white text-gray-900 font-sans">
    <!-- Overlay -->
    <div 
      v-if="isSidebarOpen" 
      class="fixed inset-0 bg-black bg-opacity-50 z-10 md:hidden"
      @click="closeSidebar"
    ></div>

    <!-- Sidebar -->
    <div :class="[
      'bg-gray-50 border-r border-gray-200 p-4 flex flex-col justify-between transition-all duration-300',
      isSidebarOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0',
      'fixed md:sticky top-0 h-full z-20 w-64'
    ]">
      <div>
        <div class="flex items-center justify-between mb-4 cursor-pointer hover:bg-gray-200 p-2 rounded transition-colors duration-200">
          <h2 class="font-semibold">Menu</h2>
          <chevron-down-icon size="20" />
        </div>
        
        <div v-for="(option, index) in gptOptions" :key="index" class="flex items-center mb-2 p-2 hover:bg-gray-200 rounded cursor-pointer transition-colors duration-200">
          <span class="mr-3 text-xl">{{ option.icon }}</span>
          <span class="text-sm">{{ option.name }}</span>
        </div>
      </div>
      
      <div class="mt-auto">
        <router-link to="/login" class="block w-full mb-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200 text-center">
          Login
        </router-link>
        <router-link to="/register" class="block w-full p-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors duration-200 text-center">
          Register
        </router-link>
      </div>
    </div>

    <!-- Main Content -->
    <div class="flex-grow flex flex-col w-full md:w-[calc(100%-16rem)]">
      <!-- Router view -->
        <router-view 
        :is-sidebar-open="isSidebarOpen"
        @toggle-sidebar="toggleSidebar"
        ></router-view>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { ChevronDown } from 'lucide-vue-next';

export default {
  name: 'App',
  components: {
    ChevronDownIcon: ChevronDown,
  },
  setup() {
    const isSidebarOpen = ref(false);
    
    const gptOptions = [
      /* { name: "PeraNETWORK", icon: "🧠" } */
    ];

    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;
    };

    const closeSidebar = () => {
      isSidebarOpen.value = false;
    };

    return {
      isSidebarOpen,
      gptOptions,
      toggleSidebar,
      closeSidebar,
    };
  }
};
</script>