<template>
    <div class="bg-gray-100 min-h-screen" v-if="carData">
      <div class="max-w-7xl mx-auto bg-white shadow-lg">
        <!-- Üst header -->
        <div class="bg-white border-b p-4 flex items-center justify-between w-full overflow-hidden">
            <div class="flex items-center min-w-0 flex-grow">
              <button @click="goBack" class="text-gray-600 mr-4 flex-shrink-0" to="/">
                <chevron-left-icon :size="24" />
              </button>
              <div class="min-w-0 flex-grow">
                <h2 class="text-xl font-semibold truncate">{{ carData.title }}</h2>
                <p class="text-2xl font-bold text-red-600 truncate">{{ carData.list_label_ad }}</p>
              </div>
            </div>
            <div class="flex items-center space-x-4 flex-shrink-0 ml-4">
              <button class="text-blue-500">
                <share-2-icon :size="24" />
              </button>
            </div>
          </div>
  
        <div class="flex flex-col md:flex-row">
          <!-- Fotoğraf bölümü -->
          <div class="w-full md:w-2/3 relative">
            <img 
              class="w-full h-64 md:h-[600px] object-cover" 
              :src="carData.photos[currentImageIndex][3]" 
              :alt="`${carData.title} - Image ${currentImageIndex + 1}`" 
            />
            <button @click="prevImage" class="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2">
              <chevron-left-icon :size="24" />
            </button>
            <button @click="nextImage" class="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2">
              <chevron-right-icon :size="24" />
            </button>
            <div class="absolute bottom-2 left-2 bg-black bg-opacity-50 text-white rounded-full px-2 py-1 text-xs">
              {{ currentImageIndex + 1 }} / {{ carData.photos.length }}
            </div>
            <div class="absolute top-2 right-2 bg-black bg-opacity-50 text-white rounded px-2 py-1 text-xs">
              AUTOVIT.RO
            </div>
          </div>
          
          <!-- Detaylar bölümü -->
          <div class="w-full md:w-1/3 p-6 overflow-y-auto max-h-[600px]">
            <div class="flex justify-between text-sm text-gray-600 mb-6">
              <span>{{ carData.listing_params[0][1] }}</span>
              <span>{{ carData.listing_params[1][1] }}</span>
              <span>{{ carData.listing_params[2][1] }}</span>
              <span>{{ carData.listing_params[3][1] }}</span>
            </div>
            
            <div class="mb-6">
              <p class="text-3xl font-bold text-red-600">{{ carData.list_label_ad }}</p>
              <p class="text-sm text-gray-600">({{ carData.net_price }} - Net)</p>
            </div>
  
            <h3 class="text-xl font-bold mb-4">Detalii</h3>
            <table class="w-full text-sm mb-6">
              <tbody>
                <tr v-for="(value, key) in carData.params" :key="key">
                  <td class="font-semibold py-2">{{ value[0] }}</td>
                  <td :class="{ 'text-blue-600': value.isLink }">{{ value[1] }}</td>
                </tr>
              </tbody>
            </table>
            
            <h3 class="text-xl font-bold mb-4">Descriere</h3>
            <div class="text-sm">
                <div 
                class="description-content" 
                v-html="formatDescription(isDescriptionExpanded ? carData.description : carData.description.slice(0, 200) + '...')"
                ></div>
                <button 
                class="text-blue-500 flex items-center mt-2"
                @click="isDescriptionExpanded = !isDescriptionExpanded"
                >
                {{ isDescriptionExpanded ? 'Ascunde descrierea' : 'Arata toata descrierea' }}
                <component :is="isDescriptionExpanded ? 'chevron-up-icon' : 'chevron-down-icon'" :size="20" />
                </button>
            </div>
          </div>
        </div>
        
        <!-- Alt butonlar -->
        <div class="bg-white border-t p-4 flex justify-between">
            <a :href="carData.url" target="_blank" rel="noopener noreferrer" class="flex-1 max-w-[45%] bg-red-600 text-white py-3 px-6 rounded-lg flex items-center justify-center text-lg no-underline hover:bg-red-700 transition-colors">
                <globe-icon :size="24" class="mr-2" />
                Vizitați Autovit.ro
              </a>
          <button class="flex-1 max-w-[45%] bg-blue-600 text-white py-3 px-6 rounded-lg flex items-center justify-center text-lg no-underline hover:bg-blue-700 transition-colors">
            <globe-icon :size="24" class="mr-2" />
            CarVertical
          </button>
        </div>
      </div>
    </div>
  </template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ChevronDown as ChevronDownIcon, ChevronUp as ChevronUpIcon, Heart as HeartIcon, Share2 as Share2Icon, Phone as PhoneIcon, Mail as MailIcon, Globe as GlobeIcon} from 'lucide-vue-next';

export default {
    name: 'AnuntPage',
    components: {
        ChevronLeftIcon,
        ChevronRightIcon,
        ChevronDownIcon,
        ChevronUpIcon,
        HeartIcon,
        Share2Icon,
        PhoneIcon,
        MailIcon,
        GlobeIcon
    },
    setup() {
        const route = useRoute();
        const isDescriptionExpanded = ref(false);
        const currentImageIndex = ref(0);
        const carData = ref(null);

        const fetchCarData = async () => {
            try {
                const response = await axios.get(`https://masinaapi.pera.network/api/ads/${route.params.id}`);
                carData.value = response.data;
            } catch (error) {
                console.error('Error fetching car data:', error);
            }
        };

        const formatDescription = (text) => {
            return text.replace(/\n\n/g, '<br><br>').replace(/\n/g, '<br>');
        };

        onMounted(fetchCarData);

        const nextImage = () => {
            currentImageIndex.value = (currentImageIndex.value + 1) % carData.value.photos.length;
        };

        const prevImage = () => {
            currentImageIndex.value = (currentImageIndex.value - 1 + carData.value.photos.length) % carData.value.photos.length;
        };

        return {
            carData,
            currentImageIndex,
            nextImage,
            prevImage,
            isDescriptionExpanded,
            formatDescription
        };
    },
    methods: {
        goBack() {
            if (window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        }
    }
}
</script>

<style scoped>
.description-content {
  white-space: pre-line; /* Yeni satırları ve boşlukları korur */
  word-wrap: break-word; /* Uzun kelimeleri satır sonunda böler */
}
</style>